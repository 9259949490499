/**
 * 
 * Author: Thomas Weiss
 * Arizona Institute for Resilience
 */
import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';

export default class Terms extends React.Component {
    constructor(props) {
        super(props);
        document.querySelector("body").scrollTo(0, 0);
    }

    render() {
        return (
            <Container fluid>
                <Row>
                    <Col className="col-12">
                        <p>These Terms and Conditions (“Terms”) govern the use of the Monsoon Fantasy Forecasts (“Monsoon Fantasy”). By playing Monsoon Fantasy, you agree to these Terms.</p>
                    </Col>
                    <Col className="col-12 text-white">
                        <ol className="terms-list">
                            <li>
                                1. Eligibility
                                <ol className="terms-list">
                                    <li>1.1. Age: You must be at least 18 years old to participate in the Monsoon Fantasy. By registering, you represent and warrant that you meet this age requirement.</li>
                                </ol>
                            </li>
                            <li>
                                2. Account Registration
                                <ol className="terms-list">
                                    <li>2.1. Account Creation: To play Monsoon Fantasy, you must create an account by providing accurate and complete information. You are responsible for maintaining the confidentiality of your account credentials.</li>
                                    <li>2.2. Account Use: You are solely responsible for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.</li>
                                </ol>
                            </li>
                            <li>
                                3. Fair Play
                                <ol className="terms-list">
                                    <li>3.1. Registered Accounts: Each user can register for one account. Creating multiple accounts will render you ineligible for recognition upon winning the game.</li>
                                </ol>
                            </li>
                            <li>
                                4. Fees
                                <ol className="terms-list">
                                    <li>4.1. Entry Fees: There are no fees.</li>
                                </ol>
                            </li>
                            <li>
                                5. Intellectual Property
                                <ol className="terms-list">
                                    <li>5.1. Ownership: All content from Monsoon Fantasy is the property of the University of Arizona.</li>
                                    <li>5.2. Use of Content: You may use the content provided in the Monsoon Fantasy for personal, non-commercial purposes only. You may not reproduce, distribute, or create derivative works from the content without our express written permission.</li>
                                </ol>
                            </li>
                            <li>
                                6. Privacy
                                <ol className="terms-list">
                                    <li>6.1. Data Collection: We will collect and use the information you fill out voluntarily in your profile and that is associated with the account, including your forecasts, actual point scores, and group participation. Aggregated, de-identified, forms of the data may be used in future scientific journal publications, like <a href="https://journals.ametsoc.org/view/journals/bams/104/1/BAMS-D-22-0003.1.xml" target="_blank" rel="noopener noreferrer">this article published in the Bulletin of the American Meteorological Society in 2022</a>. If your username is not your real name, the information associated with your account cannot be used to identify you. By using the Monsoon Fantasy, you consent to our data collection and use practices.</li>
                                    <li>6.2. Use of your email: We will periodically send out updates to the email associated with your account during the monsoon season.</li>
                                </ol>
                            </li>
                            <li>
                                7. Research
                                <ol className="terms-list">
                                    <li>Research project participation: In this season of Monsoon Fantasy, there will be two groups of participants. One group will receive weekly updates on the game, while the second group will receive one update per month. Groups will be randomly assigned to users who sign up for the game before July 1st, 2025. The research is to understand if participants had more enjoyment and interest in the game as a function of the number of game updates. Upon post-game analysis, individual data will be de-identified and aggregated as per our terms stated in section <strong>6.1: Data Collection</strong>. By using Monsoon Fantasy, you agree and consent to participate in this research project as well as our data collection and use practices.</li>
                                </ol>
                            </li>
                        </ol>
                    </Col>
                </Row>
            </Container>
        )
    }
}
